<template>
  <main>
    <sqr-progress v-show="cLoading || aLoading" />
    <sqr-error :error="aLoadError" />
    <section class="px-2 md:px-0 py-4" v-if="isManagerRW">
      <div class="container">
        <!-- prettier-ignore -->
        <nav
          class="breadcrumb"
          aria-label="breadcrumbs"
          v-if="company && account"
        >
          <ul>
            <router-link tag="li" :to="{ name: 'home' }" ><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'companies' }" ><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }" ><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }" ><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{ name: 'settings', params: { cid, aid } }" ><a>{{ $t('breadcrumb_settings') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="section" v-if="!aLoadError && account">
      <div class="container">
        <div class="columns is-desktop is-centered is-variable is-8">
          <div class="column is-4-desktop">
            <h1 class="title is-4">{{ $t('account_general') }}</h1>
            <div class="field">
              <sqr-input-text
                label="account_given_name"
                :value="account.givenName"
                @change="
                  change({ group: '', field: 'givenName', value: $event })
                "
                :disabled="disabled"
              />
            </div>
            <div class="field">
              <sqr-input-text
                label="account_family_name"
                :value="account.familyName"
                @change="
                  change({ group: '', field: 'familyName', value: $event })
                "
                :disabled="disabled"
              />
            </div>
            <div class="field">
              <sqr-input-text
                label="account_email"
                :value="account.email"
                @change="change({ group: '', field: 'email', value: $event })"
                :disabled="true"
              />
              <div class="help">{{ $t('account_email_help') }}</div>
            </div>
          </div>
          <div class="column is-4-desktop">
            <h1 class="title is-4">{{ $t('account_week_settings') }}</h1>
            <h2 class="subtitle">{{ $t('account_week_settings_help') }}</h2>
            <div class="field">
              <label class="radio">
                <input
                  type="radio"
                  name="weekType"
                  v-model="weekType"
                  value="simple"
                  :disabled="disabled"
                />
                {{ $t('account_week_settings_simple') }}
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="weekType"
                  v-model="weekType"
                  value="alternate"
                  :disabled="disabled"
                />
                {{ $t('account_week_settings_alternate') }}
              </label>
            </div>
            <div
              class="field"
              v-if="account.weekSettings.weekType === 'simple'"
            >
              <input-duration-time
                label="account_week_settings_due"
                :value="account.weekSettings.due"
                guess="hh"
                @change="
                  change({ group: 'weekSettings', field: 'due', value: $event })
                "
                :disabled="disabled"
              />
            </div>
            <div
              class="field"
              v-if="account.weekSettings.weekType === 'alternate'"
            >
              <input-duration-time
                label="account_week_settings_due_odd"
                :value="account.weekSettings.dueOdd"
                guess="hh"
                @change="
                  change({
                    group: 'weekSettings',
                    field: 'dueOdd',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
            <div
              class="field"
              v-if="account.weekSettings.weekType === 'alternate'"
            >
              <input-duration-time
                label="account_week_settings_due_even"
                :value="account.weekSettings.dueEven"
                guess="hh"
                @change="
                  change({
                    group: 'weekSettings',
                    field: 'dueEven',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
            <div class="field">
              <input-duration-time
                label="account_week_settings_max"
                :value="account.weekSettings.max"
                @change="
                  change({ group: 'weekSettings', field: 'max', value: $event })
                "
                :disabled="disabled"
              />
              <div class="help">
                {{ $t('account_week_settings_max_helper') }}
              </div>
            </div>
            <div class="field">
              <input-duration-time
                label="account_week_settings_conv"
                guess="hh"
                :value="account.weekSettings.conv"
                @change="
                  change({
                    group: 'weekSettings',
                    field: 'conv',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
          </div>
          <div class="column is-4-desktop">
            <h1 class="title is-4">{{ $t('account_year_settings') }}</h1>
            <h2 class="subtitle">{{ $t('account_year_settings_help') }}</h2>
            <div class="field">
              <input-day
                label="account_year_settings_vacations"
                :value="account.yearSettings.vacations"
                :min="0"
                :max="365"
                :step="1"
                @change="
                  change({
                    group: 'yearSettings',
                    field: 'vacations',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
            <hr />
            <div class="field">
              <update-info :value="account" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import account from './account';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import SqrInputText from '@/sqrd/SqrInputText';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import InputDay from '@/components/InputDay';
import InputDurationTime from '@/components/InputDurationTime';
import UpdateInfo from '@/components/UpdateInfo';

export default {
  name: 'Settings',
  mixins: [account],
  components: {
    SqrProgress,
    SqrError,
    SqrInputText,
    CompanyName,
    AccountName,
    InputDay,
    InputDurationTime,
    UpdateInfo,
  },
  computed: {
    ...mapGetters('perms', ['isManagerRW', 'isManagerRO']),
    ...mapGetters('auth', ['uid']),
    disabled() {
      return !(this.isManagerRW || (this.isUser && this.userId === this.uid));
    },
    weekType: {
      get() {
        return this.account?.weekSettings?.weekType;
      },
      set(value) {
        this.change({ group: 'weekSettings', field: 'weekType', value: value });
      },
    },
    keycards: {
      get() {
        if (!this?.account?.keycards) return '';
        if (typeof this?.account?.keycards === 'string')
          return this.user.keycards;
        return this?.account?.keycards?.join('\n');
      },
      set(values) {
        this.change({
          group: '',
          field: 'keycards',
          value: values
            .split('\n')
            .map(line => line.trim())
            .filter(line => line),
        });
      },
    },
  },
  methods: {
    ...mapActions('account', ['fieldSet']),
    change({ group, field, value }) {
      if (group) {
        this.fieldSet({ path: [group], field, value });
      } else {
        this.fieldSet({ field, value });
      }
      if (field === 'givenName' || field === 'familyName') {
        this.fieldSet({
          field: 'name',
          value: [this.account.givenName, this.account.familyName]
            .filter(v => Boolean(v))
            .join(' '),
        });
        this.fieldSet({
          field: 'nameSort',
          value: [this.account.familyName, this.account.givenName]
            .filter(v => Boolean(v))
            .map(v => v.toLocaleLowerCase())
            .join(' '),
        });
      }
    },
  },
};
</script>
